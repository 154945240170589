import * as React from "react";
import { Box, Button, Select, MenuItem } from "@mui/material";
import "./normdata.css";
import SkuDropDown from "../../GlobalComponent/Dropdown/SkuDropDown";
import ReasonDropdown from "../../GlobalComponent/Dropdown/SingleSelectDropdown";
import { useAuth } from "../../GlobalComponent/Authentication/AuthProvider";
import { Tooltip } from "@mui/material";
import pfContext from "../../context/PfContext";
import tooltipsContent from "../../GlobalComponent/NavBar/tooltips.json";
import Nodata from "../../GlobalComponent/NoData/Nodata";
import SpDownloadDialog from "./spDownloadDialog";

const NormDataTable = (props) => {
  const { user } = useAuth();
  const context = React.useContext(pfContext);
  const cwid = user != null && user.user_cwid != null ? user.user_cwid : "";
  const [columns, setColumns] = React.useState([]);
  const role = user !== null && user.role !== null ? user.role : "";
  const isEditableUserRole = role && (role.toLowerCase() === "e2e planner" ||
    role.toLowerCase() === "admin" ||
    role.toLowerCase() === "gsm") ? true : false;
  //const [spNormsData, setSpNormsData] = React.useState(props.spData);
  //const [month1,setMonth1]=React.useState(props.monthYear);

  const filterType = [
    { code: "SKU", Name: "Material" },
    { code: "Location", Name: "Location" },
    { code: "CoM", Name: "CoM" },
    { code: "GSMNetwork", Name: "GSM" },
    { code: "Brand", Name: "Pipeline" },
    { code: "Brand_Location", Name: "Pipeline+Location" },
    { code: "Product_Location", Name: "Material+Location" },
  ];
  const sortType = [
    { code: "TOP", Name: "Top 10" },
    { code: "LOW", Name: "Low 10" },
  ];
  const comments = [
    { code: "          ", Name: "          " },
    { code: "Too early production", Name: "Too early production" },
    { code: "Campaign size setting", Name: "Campaign size setting" },
    { code: "Adherence to planning parameters", Name: "Adherence to planning parameters" },
    { code: "Forecast reduction", Name: "Forecast reduction" },
    { code: "Underselling", Name: "Underselling" },
    { code: "Change in planning parameters", Name: "Change in planning parameters" },
    { code: "Missing special effect", Name: "Missing special effect" },
    { code: "Wrong parameter", Name: "Wrong parameter" },
    { code: "Missing demand", Name: "Missing demand" },
    { code: "Campaign effect", Name: "Campaign effect" },
    { code: "MTO", Name: "MTO" },
    { code: "Bridging stocks", Name: "Bridging stocks" },
    { code: "Implausible Norm", Name: "Implausible Norm" }
  ];

  const [selectedType, setSelectedType] = React.useState("");
  const [normsData, setNormsData] = React.useState([]);

  // const [selectedSortType, setSelectedSortType] = React.useState("TOP");
  const [selectedSku, setSelectedSku] = React.useState("SKU");
  const [selectedNorm, setSelectedNorm] = React.useState("AS-IS-NORM_Plus");
  const [filterNormsData, setFilterNormsData] = React.useState([]);
  const [showDownloadDialog, setShowDownloadDialog] = React.useState(false);
  const [showDownloadButton, setShowDownloadButton] = React.useState(true);

  const prefix = props.selectedSortType === "TOP" ? "Max" : "Min";

  const norms = [
    { code: "AS-IS-NORM_Plus", Name: `${prefix} NORM+ @as-is (smoothened)` },
    { code: "Improved-NORM_Plus", Name: `${prefix} NORM+ @improved (smoothened)` },
    { code: "Customized-NORM_Plus", Name: `${prefix} NORM+ @customized (smoothened)` },
    { code: "AS-IS-NORM", Name: `${prefix} NORM @as-is (smoothened)` },
    { code: "Improved-NORM", Name: `${prefix} NORM @improved (smoothened)` },
    { code: "Customized-NORM", Name: `${prefix} NORM @customized (smoothened)` },
  ];
  const [loader, setLoader] = React.useState(true);
  const headingStyle = () => ({
    height: "4rem",
    textAlign: "left",
    width: "100%",
    display: " flex",
    justifyContent: "space-between"
  });
  const getColumns = (data) => {
    return Object.keys(data[0]).map((key) => {
      return {
        Header: key,
        accessor: key,
      };
    });
  };

  React.useEffect(() => {
    if (props.spData !== null && props.selectedSortType !== null && selectedNorm !== null) {
      let data = [];
      let spDataSorted = {};
      const isNormPlus = norms.find(n => n.code === selectedNorm)?.code.includes('NORM_Plus');

      if (props.selectedSortType === "TOP") {
        spDataSorted = { ...props.spData['TOP'] };
      } else if (props.selectedSortType === "LOW") {
        spDataSorted = { ...props.spData['LOW'] };
      }


      const selectedData = spDataSorted[isNormPlus ? "NORM_Plus" : "NORM"];

      if (!selectedData) {
        console.warn("No data found for the selected norm type.");
        return;
      }


      if (typeof selectedData === 'object' && selectedData !== null) {
        Object.values(selectedData).forEach(function (norms) {
          if (norms != null) {
            Object.keys(norms).forEach(function (subnorms) {
              if (subnorms !== "Id") {
                if (norms[subnorms] != null && norms[subnorms][subnorms] != null) {
                  for (let i = 0; i < norms[subnorms][subnorms].length; i++) {
                    data.push({
                      Type: subnorms,
                      [subnorms]: norms[subnorms][subnorms][i]
                        ? norms[subnorms][subnorms][i].replaceAll("-", " ")
                        : norms[subnorms][subnorms][i],
                      Tick: norms[subnorms]["Tick"] && norms[subnorms]["Tick"][i]
                        ? norms[subnorms]["Tick"][i]
                        : "False",
                      Comment: norms[subnorms]["Comment"] && norms[subnorms]["Comment"][i]
                        ? norms[subnorms]["Comment"][i]
                        : "",
                      Potential: norms[subnorms]["Potential"][i] != null &&
                        norms[subnorms]["Potential"][i] != ""
                        ? norms[subnorms]["Potential"][i].toLocaleString("en-US")
                        : norms[subnorms]["Potential"][i],
                      basedon: norms["Id"],
                      [subnorms + "_QY"]: norms[subnorms][subnorms + "_QY"][i]
                        ? norms[subnorms][subnorms + "_QY"][i].replaceAll("-", " ")
                        : norms[subnorms][subnorms + "_QY"][i],
                      Tick_QY: norms[subnorms]["Tick_qy"] && norms[subnorms]["Tick_qy"][i]
                        ? norms[subnorms]["Tick_qy"][i]
                        : "False",
                      Comment_QY: norms[subnorms]["Comment_qy"] && norms[subnorms]["Comment_qy"][i]
                        ? norms[subnorms]["Comment_qy"][i]
                        : "",
                      Potential_QY: norms[subnorms]["Potential_QY"][i] != null &&
                        norms[subnorms]["Potential_QY"][i] != ""
                        ? norms[subnorms]["Potential_QY"][i].toLocaleString("en-US")
                        : norms[subnorms]["Potential_QY"][i],
                    });
                  }
                } else {
                  data.push({
                    Type: subnorms,
                    [subnorms]: "",
                    Potential: "",
                    basedon: "",
                    [subnorms + "_QY"]: "",
                    Potential_QY: "",
                    Tick: "",
                    Tick_QY: "",
                    Comment: "",
                    Comment_QY: "",
                  });
                }
              }
            });
          }
        });
      }

      if (data.length > 0) {
        setNormsData(data);
        filterPotentialData(data);
      }
    }
  }, [props.spData, props.selectedSortType, selectedNorm]);

  function filterPotentialData(data) {
    if (data.length > 0) {
      const filterData = data.filter((item) => {
        if (
          item.Type.toLowerCase() === selectedSku.toLowerCase() &&
          item.basedon.toLowerCase() === selectedNorm.toLowerCase()
        ) {
          setSelectedType(item.type);
          return item;
        }
      });
      setFilterNormsData(filterData);
      if (filterData.length > 0) {
        setColumns(getColumns(filterData));
      }
    }
  }

  React.useEffect(() => {
    if (selectedSku != "" && selectedNorm != "") {
      const newNormsData = removeZeroes(normsData);
      filterPotentialData(newNormsData);
    }
  }, [selectedNorm, selectedSku]);


  const handleTickChange = (index, colAccessor) => {
    const newFilterNormsData = [...filterNormsData];
    newFilterNormsData[index][colAccessor] = newFilterNormsData[index][colAccessor] === "False" ? "True" : "False";
    const removedZero = removeZeroes(newFilterNormsData);
    setFilterNormsData(removedZero);
  };
  const handleCommentDropdownChange = (value, index, colAccessor) => {
    const newFilterNormsData = [...filterNormsData];
    newFilterNormsData[index][colAccessor] = value ? value : "";
    setFilterNormsData(newFilterNormsData);
  };

  const handleSpSave = () => {
    props.handleSavingPotentialSave(filterNormsData)
  }

  const removeZeroes = (data) => {
    return data.map(f => {
      if (f.Product_Location) {
        f.Product_Location = f.Product_Location.replace(/^0+/, '');
      }
      return f;
    })
  }
  const handleSpDownload = () => {
    setShowDownloadDialog(true)
  }
  React.useEffect(() => {
    if (selectedSku === "Brand_Location" || selectedSku === "Product_Location" || selectedNorm === "Customized-NORM_Plus" || selectedNorm === "Customized-NORM") {
      setShowDownloadButton(false);
    } else {
      setShowDownloadButton(true)
    }
  }, [selectedSku, selectedNorm])

  const handleSortDropdownChange = (value) => {
    props.onSortTypeChange(value); 
  };
  return (
    normsData.length > 0 && (
      <>
        <Box
          className="boxFlex"
          style={{ flexDirection: "column", padding: "0 0 0 2.4rem" }}
        >
          <div className="title_2" style={headingStyle()}>
            Saving Potential
            <Box >
              {showDownloadButton && <Button
                id="spDownloadButton"
                className="load download-button"
                onClick={handleSpDownload}
              >
                {"Download"}
              </Button>}

              <Button
                id="spEditButton"
                className="load"
                onClick={handleSpSave}
              // style={{ width: "14rem" }}
              // value={chartButton}
              >
                {"Save Changes"}
              </Button>
            </Box>
          </div>
          <div className="boxFlex" style={{ width: "100%" }}>
            <Tooltip
              title={
                <span style={{ fontSize: "16px" }}>
                  {tooltipsContent.SavingPotentialsLeft}
                </span>
              }
              open={
                context.showTooltips && context.buttonHoverStates === "title_7"
              }
            >
              <div
                style={{ width: "40%", display: "flex", alignItems: "center" }}
              >
                {/* <span className="title_7">Top 10</span> */}
                <SkuDropDown
                  options={sortType}
                  style={{ width: "10.5rem" }}
                  value={props.selectedSortType}
                  setValue={handleSortDropdownChange}
                  label="sortType"
                />
                <span
                  style={{ marginLeft: "1rem" }}
                  onMouseEnter={() => context.setButtonHoverStates("title_7")}
                  onMouseLeave={() => context.setButtonHoverStates("")}
                >
                  <SkuDropDown
                    options={filterType}
                    style={{ width: "13rem" }}
                    value={selectedSku}
                    setValue={setSelectedSku}
                    label="SKUs"
                  />
                </span>
              </div>
            </Tooltip>
            <Tooltip
              title={
                <span style={{ fontSize: "16px" }}>
                  {tooltipsContent.SavingPotentialsRight}
                </span>
              }
              open={
                context.showTooltips && context.buttonHoverStates === "Based on"
              }
            >
              <div
                style={{
                  width: "60%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
                onMouseEnter={() => context.setButtonHoverStates("Based on")}
                onMouseLeave={() => context.setButtonHoverStates("")}
              >
                <span className="title_7">Based on</span>
                <span style={{ marginLeft: "1rem" }}>
                  <Select
                    style={{ width: "20rem" }}
                    value={selectedNorm}
                    onChange={(event) => {
                      const newValue = event.target.value;
                      setSelectedNorm(newValue);
                      setNormsData([]);
                    }}
                  >
                    {norms.map((norm) => (
                      <MenuItem key={norm.code} value={norm.code}>
                        {norm.Name}
                      </MenuItem>
                    ))}
                  </Select>
                </span>
              </div>
            </Tooltip>
          </div>
          <div style={{ marginTop: "1.6rem", width: "100%" }}>
            {filterNormsData.length > 0 ? (
              <table
                style={{ position: "auto", width: "98%" }}
                className="savingPotentielTable"
              >
                <thead>
                  <tr className="title_5" style={{ color: "#9B9B9B" }}>
                    {columns.map((col, index) => {
                      if (col.Header != "basedon" && col.accessor !== "Type") {
                        if (
                          props.chartButtonVal === "VAL" &&
                          !col.accessor.includes("_QY")
                        ) {
                          return (
                            <th key={index} style={{ width: col.Header === "Tick" ? "20%" : "40%" }}>
                              {col.Header == "SKU"
                                ? "Material"
                                : col.Header == "Brand"
                                  ? "Pipeline"
                                  : col.Header == "Brand_Location"
                                    ? "Pipeline_Location"
                                    : col.Header === "Product_Location"
                                      ? "Material_Location"
                                      : col.Header === "Tick"
                                        ? "Checked"
                                        : col.Header === "Comment"
                                          ? "Reason"
                                          : col.Header}
                            </th>
                          );
                        } else if (
                          props.chartButtonVal === "QTY" &&
                          col.accessor.includes("_QY")
                        ) {
                          return (
                            <th key={index} style={{ width: col.Header === "Tick_QY" ? "20%" : "40%" }}>
                              {col.Header == "SKU_QY"
                                ? "Material"
                                : col.Header == "Brand_QY"
                                  ? "Pipeline"
                                  : col.Header == "Location_QY"
                                    ? "Location"
                                    : col.Header == "GSMNetwork_QY"
                                      ? "GSMNetwork"
                                      : col.Header == "Brand_Location_QY"
                                        ? "Pipeline_Location"
                                        : col.Header === "Product_Location_QY"
                                          ? "Material_Location"
                                          : col.Header === "Tick_QY"
                                            ? "Checked"
                                            : col.Header === "Comment_QY"
                                              ? "Reason"
                                              : col.Header === "Potential_QY"
                                                ? "Potential"
                                                : col.Header}
                            </th>
                          );
                        }
                      }
                    })}
                  </tr>
                </thead>
                <tbody>
                  {filterNormsData.map((data, index) => (
                    <tr key={index}>
                      {columns.map((col, colIndex) => {
                        if (
                          col.accessor != "basedon" &&
                          col.accessor !== "Type"
                        ) {
                          if (
                            props.chartButtonVal === "VAL" &&
                            !col.accessor.includes("_QY")
                          ) {

                            return (
                              col.Header === "Tick" ? <td
                                key={`td_${col.accessor}-${index}`}
                                id={`td_${col.accessor}-${index}`}
                              >
                                <input
                                  key={`td_${col.accessor}-${index}`}
                                  type="checkbox"
                                  checked={data[col.accessor].toLowerCase() === 'true'}
                                  disabled={!isEditableUserRole}
                                  onChange={() => handleTickChange(index, col.accessor)}
                                  className="sp-checkbox"

                                />
                              </td>
                                : col.Header === "Comment" ? <td
                                  key={`td_${col.accessor}-${index}`}
                                  id={`td_${col.accessor}-${index}`}
                                >
                                  <ReasonDropdown
                                    style={{ width: "16rem" }}
                                    options={comments}
                                    value={data[col.accessor]}
                                    setValue={(value) => handleCommentDropdownChange(value, index, col.accessor)}
                                    label=""
                                    isEditableUserRole={isEditableUserRole}
                                    dropdownType="spReason"
                                  />
                                </td>
                                  :
                                  <td
                                    key={`td_${col.accessor}-${index}`}
                                    id={`td_${col.accessor}-${index}`}
                                  >
                                    {data[col.accessor] !== null &&
                                      data[col.accessor] !== "" &&
                                      data[col.accessor] !== undefined &&
                                      col.Header === "SKU"
                                      ? data[col.accessor].replace(/^0+/, "")
                                      : data[col.accessor]}
                                  </td>
                            );
                          } else if (
                            props.chartButtonVal === "QTY" &&
                            col.accessor.includes("_QY")
                          ) {
                            return (
                              col.Header === "Tick_QY" ? <td
                                key={`td_${col.accessor}-${index}`}
                                id={`td_${col.accessor}-${index}`}
                              >
                                <input
                                  key={`td_${col.accessor}-${index}`}
                                  type="checkbox"
                                  checked={data[col.accessor].toLowerCase() === 'true'}
                                  disabled={!isEditableUserRole}
                                  onChange={() => handleTickChange(index, col.accessor)}
                                  className="sp-checkbox"

                                />
                              </td>
                                : col.Header === "Comment_QY" ? <td
                                  key={`td_${col.accessor}-${index}`}
                                  id={`td_${col.accessor}-${index}`}
                                >
                                  <ReasonDropdown

                                    style={{ width: "16rem" }}
                                    options={comments}
                                    value={data[col.accessor]}
                                    setValue={(value) => handleCommentDropdownChange(value, index, col.accessor)}
                                    label=""
                                    isEditableUserRole={isEditableUserRole}
                                    dropdownType="spReason"
                                  />
                                </td>
                                  :
                                  <td
                                    key={`td_${col.accessor}-${index}`}
                                    id={`td_${col.accessor}-${index}`}
                                  >
                                    {data[col.accessor] !== null &&
                                      data[col.accessor] !== "" &&
                                      data[col.accessor] !== undefined
                                      ? data[col.accessor]?.replace(/^0+/, "")
                                      : data[col.accessor]}
                                  </td>
                            );
                          }
                        }
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <Nodata message="No Data" />
              // <table style={{ position: "relative" }}>
              // 	<tbody>
              // 		<tr>
              // 			<td style={{ width: "100%", textAlign: "center" }}></td>
              // 		</tr>
              // 	</tbody>
              // </table>
            )}
          </div>
        </Box>
        {showDownloadDialog &&
          <SpDownloadDialog
            showDownloadDialog={showDownloadDialog}
            setShowDownloadDialog={setShowDownloadDialog}
            selectedSku={selectedSku}
            selectedNorm={selectedNorm}
            chartButtonVal={props.chartButtonVal}
            monthYear={props.monthYear}
          />
        }
      </>
    )
  );
};

export default NormDataTable;
