import React, { useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import SkuDropDown from '../../GlobalComponent/Dropdown/SkuDropDown';
import axios from "axios";
import { spDownloadquery } from './GraphQueryNew';
import pfContext from "../../context/PfContext";
import { useAuth } from "../../GlobalComponent/Authentication/AuthProvider";
import * as XLSX from 'xlsx';


const sortType = [
  { code: 10, Name: '10' },
  { code: 20, Name: '20' },
];

const SpDownloadDialog = (props) => {
  const { user } = useAuth();
  const context = React.useContext(pfContext);
  const cwid = user != null && user.user_cwid != null ? user.user_cwid : "";

  const [topLowSelection, setTopLowSelection] = useState('Top');
  const [selectedSortType, setSelectedSortType] = useState(10);
  const [checkedItems, setCheckedItems] = useState({
    pipeline: false,
    com: false,
    location: false,
  });
  const checkboxData = [
    { name: 'pipeline', label: 'Pipeline' },
    { name: 'com', label: 'CoM' },
    { name: 'location', label: 'Location' },
  ];
  const [open, setOpen] = useState(props.showDownloadDialog);

  const handleChange = (event, newAlignment) => {
    setTopLowSelection(newAlignment);
  };

  const handleCheckboxChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  const handleClose = () => {
    setOpen(false);
    props.setShowDownloadDialog(false);
  };

  // Function to handle download
  const handleDownload = async () => {
    const cancelToken = axios.CancelToken.source();
    const { gsmCode, locCode, comCode, proCode, pipCode } = context.getFilterValues();


    const inputParamSpDownload = `
    Brand_Sel_All: ${context.isBrandSelectAll}, 
    CoM_Sel_All: ${context.isCoMSelectAll}, 
    GSM_Sel_All: ${context.isGsmSelectAll}, 
    Location_Sel_All: ${context.isLocationSelectAll}, 
    Product_Sel_All: ${context.isProductSelectAll}, 
    Brand_Values: "${pipCode}", 
    Location_Values: "${locCode}", 
    Product_Values: "${proCode}", 
    CoM_Values: "${comCode}", 
    GSM_Values: "${gsmCode}", 
    CWID: "${cwid}", 
    snapshot: "${props.monthYear}", 
    aggregation_level_selection: "${props.selectedSku}", 
    com_check: ${checkedItems.com}, 
    location_check: ${checkedItems.location}, 
    pipeline_check: ${checkedItems.pipeline}, 
    norm_selection: "${props.selectedNorm}", 
    top_low_selection: "${topLowSelection}", 
    top_x: ${selectedSortType}, 
    val_qty_selection: "${props.chartButtonVal.toLowerCase()}"
  `;

    try {
      const response = await spDownloadquery(inputParamSpDownload, cancelToken.token);
      const spDownloadData = response.data.PotentialFinderSavingPotentialDownload;
      if (spDownloadData?.SavingPotentialDownloadTable?.length > 0) {

        const filterSettingsObject = spDownloadData?.SavingPotentialDownloadFilterSettings;
        const filterSettingsArray = filterSettingsObject ? [filterSettingsObject] : []; // Wrap the object in an array

        // Convert JSON data to worksheets
        const sheet1 = XLSX.utils.json_to_sheet(spDownloadData.SavingPotentialDownloadTable || []);
        const sheet2 = XLSX.utils.json_to_sheet(filterSettingsArray);

        // Create a new workbook
        const workbook = XLSX.utils.book_new();

        // Append sheets to the workbook
        XLSX.utils.book_append_sheet(workbook, sheet1, 'Saving Potential');
        XLSX.utils.book_append_sheet(workbook, sheet2, 'Filter Settings');
        // Write the workbook to an Excel file and trigger the download
        XLSX.writeFile(workbook, 'SavingPotential.xlsx');
        props.setShowDownloadDialog(false)
      } else {
        console.log("No Data");
        props.setShowDownloadDialog(false)

      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error("Error in fetching data", error);
      }
    }
  };

  return (
    <Box   >
      <Dialog
        onClose={handleClose}
        aria-labelledby="dialog"
        open={open}
        maxWidth="xs"
        fullWidth
        className='sp-download-dialog'
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="dialog-title">
          Download Saving Potential
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
            <Box className="top-content" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <ToggleButtonGroup
                color="primary"
                value={topLowSelection}
                exclusive
                onChange={handleChange}
                aria-label="Selection"
              >
                <ToggleButton value="Top">Top</ToggleButton>
                <ToggleButton value="Low">Low</ToggleButton>
              </ToggleButtonGroup>
              <SkuDropDown
                options={sortType}
                style={{ width: '10.5rem' }}
                value={selectedSortType}
                setValue={setSelectedSortType}
                label="sortType"
              />
            </Box>
            <FormGroup sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {checkboxData.map((checkbox, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={checkedItems[checkbox.name] || false}
                      onChange={handleCheckboxChange}
                      name={checkbox.name}
                      // color={checkbox.color}
                      className='sp-c'
                    />
                  }
                  label={<span className="checkbox-label" >{checkbox.label}</span>}
                />
              ))}
            </FormGroup>
            Selected checkmark will create a seperate list per filter selection
          </Box>
        </DialogContent>
        <DialogActions>
          <Button className='load' onClick={handleDownload}>
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SpDownloadDialog;
