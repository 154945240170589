import React from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css'; // External CSS for styling
import { useAuth } from "./Authentication/AuthProvider";
import axios from "axios";
import { endpoint, key } from "./util";

const LandingPage = () => {
    const sections = [
        {
            image: '../../AppAssets/potentialFinder.png',
            title: 'Potential Finder',
            description: 'Identify inventory saving potentials and simulate impact of saving measures.',
            link: '/finder'
        },
        {
            image: '../../AppAssets/EffectPlanning.png',
            title: 'Effect Planning',
            description: 'Input inventory forecast, deviation trend, and Norm measures.',
            link: '/effect'
        },
        {
            image: '../../AppAssets/targetSteering.png',
            title: 'Target Steering',
            description: 'Identify inventory development with Target and Norm, examine correct contributions.',
            link: "https://app.powerbi.com/groups/816ba5ee-5a3e-49a9-a4c0-a69d1f626333/reports/40472567-baba-4a29-8f80-b911b5bf0001/ReportSection89dedebcbdea2ab40b80?bookmarkGuid=Bookmark2d5dbd752ddd4e000146"
        },
        {
            image: '../../AppAssets/TptAnalytics.png',
            title: 'TPT Analytics',
            description: 'Examine trends and analyse lead time reduction potentials, deepdive down to batch level',
            link: "https://app.powerbi.com/groups/5bb4f8bd-ab20-4ed0-8d07-653ca0c52e68/reports/6613cc16-c9af-4d2f-a82c-2533d1b245a7/ReportSectionc96c6f83aede47276b90?experience=power-bi"
        },
        {
            image: '../../AppAssets/AnalysesAlerts.png',
            title: 'Analyses & Alerts',
            description: 'Analyse inventory trends and correlations, trigger critical alerts and track for outliers.',
            link: "https://app.powerbi.com/groups/816ba5ee-5a3e-49a9-a4c0-a69d1f626333/reports/37ea9fa4-d255-403d-8902-774dba0644f8/ReportSection543523c8149543628f23"
        },
        {
            image: '../../AppAssets/ReleaseTimeTracking.png',
            title: 'Release Time Tracking',
            description: 'Analyse monthly and YTD release times vs targets.',
            link: "https://app.powerbi.com/groups/5bb4f8bd-ab20-4ed0-8d07-653ca0c52e68/reports/a2cba1bc-b447-46bf-b5e7-5e54886311d1/ReportSection9370cf101ed60847827a"
        }
    ];
    const { user } = useAuth();
    const cwid = (user != null && user.user_cwid != null) ? user.user_cwid : "";
    
    
    const handlePageClick = (portal) => {
        try {
            const getdata = async () => {
                //setLoader(true);
                const response = await axios({
                    url: endpoint,
                    method: "POST",
                    headers: { "x-api-key": key },
                    data: {
                        query: `query MyQuery {
                            CaptureUserActivity(portlet_name: "${portal === 'Analyses & Alerts' ? 'Alert & Analysis' : portal}", user_cwid: "${cwid}")
							}`,
                    },
                }).then((response) => {
                    if (
                        response.data != undefined &&
                        response.data.data != undefined &&
                        response.data.data["CaptureUserActivity"] != null
                    ) {


                    }


                });
            };

            getdata();
        } catch (error) {
            console.log(error);

        }
    }
    return (
        <div className="landing-page">
            <div className="dashboard-sections">
                {sections.map((section, index) => {
                    if (index <= 1) {
                        return <Link to={section.link} key={index} className="section-link">
                            <div className="section-item">
                                <img src={section.image} alt={section.title} className="section-image" />
                                <div className="section-content">
                                    <h3>{section.title}</h3>
                                    <p>{section.description}</p>
                                </div>
                            </div>
                        </Link>

                    } else {

                        return <a
                            key={index}
                            href={section.link}
                            target={index > 1 ? "_blank" : undefined}  // Open in new tab except first two
                            rel={index > 1 ? "noopener noreferrer" : undefined}  // Security for new tabs
                            className="section-link"
                            onClick={() => handlePageClick(section.title)}
                        >
                            <div className="section-item">
                                <img src={section.image} alt={section.title} className="section-image" />
                                <div className="section-content">
                                    <h3>{section.title}</h3>
                                    <p>{section.description}</p>
                                </div>
                            </div>
                        </a>
                    }
                })}
            </div>
        </div>
    );
};

export default LandingPage;